import React, { useMemo } from "react";
import Layout from "../components/layout";
import useAllProducts from "../hooks/useAllProduct";
import ProductTemplate from "../templates/product";
import Measure from "../components/sections/measure";
import ServiceAreaForm from "../components/partials/service-areas";
import RecommendedProducts from "../components/partials/recommended-products";

const SteelCoversProduct = () => {
  const products = useAllProducts();
  const steelCovers = useMemo(() => {
    if (!products) return [];

    const standard = products.find(
      ({ node }) => node.product.sku === "window-well-cover-steel-standard"
    );
    const premium = products.find(
      ({ node }) => node.product.sku === "window-well-cover-steel-premium"
    );

    return [
      { ...premium.node, label: "Premium" },
      { ...standard.node, label: "Standard" },
    ];
  }, [products]);

  if (!steelCovers.length) {
    return <div>No Products</div>;
  }

  return (
    <Layout>
      <ProductTemplate products={steelCovers} />
      <div className="container lg:my-24">
        <RecommendedProducts
          products={products}
          cover="plastic-polycarbonate-window-well-covers"
        />
        <Measure product={steelCovers[0].product} />
        <div className="max-w-md">
          <ServiceAreaForm />
        </div>
      </div>
    </Layout>
  );
};
export default SteelCoversProduct;
